import "./StyledInput.scss";

import React from "react";

// base component
interface StyledInputBaseProps<T> {
  label?: string;
  value: T;
  setValue: (value: T) => void;
  type: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  htmlFor?: string;
}

export const StyledInputBase: React.FC<StyledInputBaseProps<any>> = ({
  label,
  children,
  className,
  style,
  htmlFor,
}) => {
  return (
    <div className={`styledInput ${className || ""}`} style={style}>
      {label && <label htmlFor={htmlFor}>{label}</label>}
      {children}
    </div>
  );
};
